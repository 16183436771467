import { Column, Container, Grid, Section } from '../components/styled/layout'
import { Heading, Intro, Title } from '../components/styled/typography'
import { StaticQuery, graphql } from 'gatsby'

import Img from 'gatsby-image'
import JoinNewsletter from '../components/UI/JoinNewsletter'
import Layout from '../components/layout'
import React from 'react'
import SEO from '../components/SEO/SEO'

const EventPage = () => {
  return (
    <StaticQuery
      query={graphql`
        query CreandiaPageQuery {
          allAirtable(
            filter: {
              table: { eq: "Sidor" }
              data: { Sida: { eq: "Creandia" } }
            }
          ) {
            edges {
              node {
                data {
                  Sida

                  Huvudbild {
                    localFiles {
                      childImageSharp {
                        fluid(maxWidth: 900) {
                          ...GatsbyImageSharpFluid_withWebp
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      `}
      render={data => {
        const sida = data.allAirtable.edges[0].node.data
        return (
          <React.Fragment>
            <SEO title={'Om Creandia'} />
            <Layout>
              <Section>
                <Container>
                  <Grid columns="1fr">
                    <Column>
                      <div>
                        <Title>
                          Om Creandia.{' '}
                          <span>En skön mix av kompetens och knas.</span>
                        </Title>
                      </div>
                    </Column>
                  </Grid>
                </Container>
              </Section>
              <Section padding="0px 0px 4vmax 0px">
                <Img
                  fluid={sida.Huvudbild.localFiles[0].childImageSharp.fluid}
                />
              </Section>
              <Section id="more">
                <Container width="900px">
                  <Column>
                    <Heading>
                      Creandia skapades från en vilja att göra världen lite
                      bättre.
                    </Heading>
                    <Intro subtle style={{ marginBottom: '3vmax' }}>
                      Genom evenemang såg vi möjligheten att föra samman
                      människor i en allt mer uppkopplad värld. Vi tror på mötet
                      mellan personer, och att det kan skapa starkare band och
                      djupare relationer.
                    </Intro>
                    <p>
                      ”Syns du inte, finns du inte”, ett uttryck som de flesta
                      har hört. Räddningstjänsten syd använde det till och med
                      som sin slogan för en kampanj om reflexvästar, genialiskt
                      kan vi tycka. Hur du syns handlar så mycket mer om dina
                      marknadsföringsinsatser, det handlar om att vara tydlig
                      och konsekvent.
                      <br /> <br />
                      Hur kommer det sig att vi kan börja tänka på ett företag
                      genom att enbart se en specifik färg, att höra en viss
                      melodi eller att läsa en specifik mening? Företag som har
                      förstått vikten av att vara konsekventa i sin
                      kommunikation vet också vilken slagkraft det kan ge. Varje
                      detalj i din verksamhet som syns utåt bör återspegla din
                      verksamhets inre. Färg, form, melodi och språk, allt ska
                      kunna kopplas direkt till din verksamhet och ska alltid
                      återspegla verksamhetens värderingar och målgrupp.
                    </p>
                  </Column>
                </Container>
              </Section>
              <Section padding="0px 0px 4vmax 0px">
                <Img
                  fluid={sida.Huvudbild.localFiles[1].childImageSharp.fluid}
                />
              </Section>
              <Section>
                <Container width="900px">
                  <Column>
                    <Heading>
                      Creandia slutar aldrig att utvecklas. Vi skapar nya sätt
                      för människor att synas, att mötas.
                    </Heading>
                    <Intro subtle style={{ marginBottom: '3vmax' }}>
                      Vi började som en eventbyrå men utvecklades även naturligt
                      till en kommunikationsbyrå. På samma sätt som alla företag
                      och verksamheter behöver kommunikation, behöver
                      event-kommunikationen vara tydlig och konsekvent.
                    </Intro>
                    <p>
                      Vi besitter ett brett nätverk och en ännu bredare
                      kompetens. Detta gör att vi kan leverera allt från koncept
                      till något konkret, utan att du behöver tänka på något
                      annat bortom resultatet. Vi producerar grafiska
                      identiteter, reklammaterial, marknadsföringsplaner och
                      utvecklar din kommunikation både digitalt och analogt. Med
                      oss som partner har du alltid någon att vända dig till
                      gällande dina kommunikationsbekymmer, någon som lyssnar
                      och som alltid kan leverera konsekventa resultat.
                      <br /> <br />
                      Oavsett om det är något man kan känna på, lukta på, se på
                      eller smaka på. Kanske är det något man kan interagera
                      med, lära sig med eller utvecklas med. Vi älskar att skapa
                      upplevelser, och vi vill att din kommunikation ska bli en
                      unik upplevelse för slutanvändaren.
                    </p>
                  </Column>
                </Container>
              </Section>
            </Layout>
          </React.Fragment>
        )
      }}
    />
  )
}

export default EventPage
